document.addEventListener('DOMContentLoaded', function() {
    'use strict';
    // --------------
    // variables
    // --------------
    const hello = 'Hello world!';
    const lazyLoadInstance = new LazyLoad({
        elements_selector: '.lazy',
    });
    // --------------
    // functions
    // --------------
    console.log(hello);
    // --------------
    // event listners & starters
    // --------------
    // fires lazy load
    if (lazyLoadInstance) {
        lazyLoadInstance.update();
    }
});
